import React, { useEffect, useRef, useState } from 'react';
import { getter } from './utils';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { Button, Box, Paper, Stack, Table, TableBody, TableCell, TableRow, Typography, TableHead, Tooltip, IconButton } from '@mui/material';
import SelectMUI from 'components/mui/select/select.component';
import Carousel from 'react-material-ui-carousel';
import { ArrowBackIcon, ArrowForwardIcon, Calendar30DaysIcon, CarrotDownIcon, CarrotUpIcon, FiberManualRecordIcon, LocationOnIcon, MoneyIcon, StatusDotIcon } from 'components/mui';
import CircularProgress from '@mui/material/CircularProgress';
const fieldsToExclude = ['location_friendly_name', 'status', 'start_when', 'end_when', 'created_when', 'agreement_length', 'name', 'updated_when'];
export function SubscriptionsComponent(props) {
  const {
    subscriptions,
    customerDetails,
    availableModelsFields,
    availableModels,
    isLoading,
    _detailsTabs,
    handleDetailsTab,
    selectedDetailsTab
  } = props;
  const [showMoreFields, setShowMoreFields] = useState(false);
  const [selectedTransactionIndex, setSelectedTransactionIndex] = useState(0);
  const getFilteredFields = subscription => {
    return subscriptionFields?.filter(f => subscription[f?.id] != undefined && subscription[f?.id] != null && subscription[f?.id] != '' || f.id.substring(0, 13) === 'entity_data__' || f.id.substring(0, 15) === 'appended_data__');
  };
  const subscriptionFields = availableModelsFields?.find(model => model.id === 'subscription')?.fields?.filter(f => !fieldsToExclude.includes(f.id));
  return <>
      <Stack sx={{
      height: '100%',
      background: '#E4E9F1',
      borderBottomLeftRadius: '28px',
      borderBottomRightRadius: '28px',
      overflowY: 'scroll',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        marginTop: '25px',
        marginBottom: '25px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    }} className="d-flex h-100" spacing={'14px'}>
        <Box className="d-flex w-100  justify-content-between" sx={{
        position: 'relative',
        pt: '14px',
        pb: '38px',
        display: 'flex'
      }}>
          <span />
          <Box sx={{
          position: 'absolute',
          left: '37.5%'
        }}>
            <SelectMUI height="36px" width="180px" top="6px" zIndex={9001} options={_detailsTabs} value={selectedDetailsTab || ''} onChange={opt => handleDetailsTab(opt.key)} />
          </Box>
          {subscriptions?.length > 0 && <Box sx={{
          position: 'absolute',
          left: '77%',
          top: '24px'
        }}>
              {'' + (selectedTransactionIndex + 1) + ' of ' + subscriptions?.length + ' Items'}
            </Box>}
        </Box>
        <Stack>
          {isLoading ? <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          pt: '25px'
        }}>
              <CircularProgress />
            </Box> : <>
              {subscriptions?.length > 0 ? <Carousel index={selectedTransactionIndex} navButtonsAlwaysVisible cycleNavigation={false} autoPlay={false} animation="slide" indicators={false} PrevIcon={<ArrowBackIcon fill="#fff" />} NextIcon={<ArrowForwardIcon fill="#fff" />} onChange={(now, prev) => setSelectedTransactionIndex(now)}>
                  {subscriptions?.map((subscription, i) => <Box key={subscription.id + i} sx={{
              px: '50px'
            }}>
                      <Paper sx={{
                borderRadius: '14px',
                overflowY: 'scroll',
                scrollbarWidth: 'thin',
                '&::-webkit-scrollbar': {
                  width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                  marginTop: '10px',
                  marginBottom: '10px'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888'
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555'
                },
                height: '70vh'
              }}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Typography variant="tableHeader">Name </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>{subscription?.name || <Typography sx={{
                          color: 'BEBEBE'
                        }}>--</Typography>}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <LocationOnIcon />
                                  <Typography variant="tableHeader">Location </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                {subscription?.location_friendly_name || <Typography sx={{
                          color: 'BEBEBE'
                        }}>--</Typography>}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Typography variant="tableHeader">Status </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <Stack direction="row" spacing="8px">
                                  <StatusDotIcon fill={subscription?.status?.toLowerCase() == 'active' ? '#6C9' : '#F66'} />
                                  <Box sx={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                                    <Typography>{subscription?.status?.toLowerCase() == 'active' ? 'Active' : 'Inactive'} </Typography>
                                  </Box>
                                </Stack>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Calendar30DaysIcon />
                                  <Typography variant="tableHeader">Created Date </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                {subscription?.created_when ? moment.utc(subscription.created_when).local().format('MM/D/YY h:mm A') : <Typography sx={{
                          color: 'BEBEBE'
                        }}>--</Typography>}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Calendar30DaysIcon />
                                  <Typography variant="tableHeader">Start Date </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                {subscription?.start_when ? moment.utc(subscription.start_when).local().format('MM/D/YY h:mm A') : <Typography sx={{
                          color: 'BEBEBE'
                        }}>--</Typography>}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Calendar30DaysIcon />
                                  <Typography variant="tableHeader">End Date </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                {subscription?.end_when ? moment.utc(subscription.end_when).local().format('MM/D/YY h:mm A') : <Typography sx={{
                          color: 'BEBEBE'
                        }}>--</Typography>}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <Stack spacing="10px" direction="row" sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>
                                  <Calendar30DaysIcon />
                                  <Typography variant="tableHeader">Agreement Length </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                {subscription?.agreement_length || <Typography sx={{
                          color: 'BEBEBE'
                        }}>--</Typography>}
                              </TableCell>
                            </TableRow>

                            {availableModels.indexOf('subscription') > -1 && getFilteredFields(subscription)?.filter((f, index) => showMoreFields)?.map((field, index) => {
                      const fieldValue = subscription[field.id];
                      return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' ? <TableRow key={field.id + index}>
                                      <TableCell>
                                        <Typography variant="tableHeader">{field.name} </Typography>
                                      </TableCell>

                                      <TableCell>
                                        {field.type === 'datetime' ? moment(fieldValue).local().format('MMM D, YYYY h:mm A') : field.type === 'date' ? moment(fieldValue).format('MMM D, YYYY') : fieldValue}
                                      </TableCell>
                                    </TableRow> : field.id.substring(0, 13) === 'entity_data__' ? <TableRow key={index}>
                                      <TableCell>
                                        <Typography variant="tableHeader">{field.name} </Typography>
                                      </TableCell>
                                      <TableCell>
                                        {getter(subscription, field.id.split('__')) || <Typography sx={{
                            color: '#BEBEBE'
                          }}>--</Typography>}
                                      </TableCell>
                                    </TableRow> : field.id.substring(0, 15) === 'appended_data__' ? <TableRow key={index}>
                                      <TableCell>
                                        <Typography variant="tableHeader">{field.name} </Typography>
                                      </TableCell>
                                      <TableCell>
                                        {getter(subscription, field.id.split('__')) || <Typography sx={{
                            color: '#BEBEBE'
                          }}>--</Typography>}
                                      </TableCell>
                                    </TableRow> : <React.Fragment key={field.id} />;
                    })}

                            {getFilteredFields(subscription)?.length > 0 && <TableRow sx={{
                      cursor: 'pointer'
                    }} onClick={() => setShowMoreFields(!showMoreFields)}>
                                <TableCell sx={{
                        py: '8px'
                      }}>
                                  <Typography sx={{
                          color: '#1D252D80'
                        }}>
                                    {'... ' + getFilteredFields(subscription).length + (showMoreFields ? ' less items' : ' more items')}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{
                        py: '8px'
                      }}>
                                  <Box className="d-flex  justify-content-between">
                                    <span />
                                    <Button>
                                      <Stack sx={{
                              display: 'flex',
                              alignItems: 'center'
                            }} direction="row" spacing="10px">
                                        <Typography sx={{
                                color: '#3898D9',
                                textTransform: 'none'
                              }}>
                                          {showMoreFields ? 'View less' : 'View all'}
                                        </Typography>
                                        {showMoreFields ? <CarrotUpIcon /> : <CarrotDownIcon />}
                                      </Stack>
                                    </Button>
                                  </Box>
                                </TableCell>
                              </TableRow>}
                          </TableBody>
                        </Table>
                      </Paper>
                    </Box>)}
                </Carousel> : <Stack>
                  <Box sx={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%'
            }}>
                    <Typography>Customer has no subscriptions</Typography>
                  </Box>
                </Stack>}
            </>}
        </Stack>
      </Stack>
    </>;
}