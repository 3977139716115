import React, { useState, useRef, useEffect, useContext } from 'react';
import { Scoped } from 'kremling';
import Papa from 'papaparse';
import { get, map, set } from 'lodash';
import { getCSVImportSources, importCSV, getSegments, patchCompany, previewCSV, getCompanyFields, getAICSVMapping, generateAICSVMapping, createAICSVMapping, updateAICSVMapping } from 'shared/common.api';
import styles from './upload.styles.scss';
import { UserStateContext } from 'context/user-state-context';
import constants from 'shared/constants';
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { createMultipartUpload, listParts, prepareUploadPart, completeMultipartUpload, abortMultipartUpload } from 'components/file-viewer/provider';
import { Button, Modal, toasterService, PageHeaderMui, Icon, DynamicTypeAheadMui } from 'components';
import { Box, Stack, Typography, Paper, Divider, IconButton, Button as MUIButton, TextField, MenuItem, Select, Grid, Autocomplete, Table, TableHead, TableRow, TableCell, TableBody, Dialog, DialogContent, DialogActions, CircularProgress, Checkbox, Radio, FormControl, InputLabel, Chip, InputAdornment, TableContainer } from '@mui/material';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { AddIcon, ArrowCooldownIcon, ArrowRightAltIcon, Calendar30DaysIcon, CheckIcon, CloseIcon, CsvImportWizardIcon, CustomerIcon, CustomersIcon, HelpIcon, ImportIcon, NewsIcon, OpenInNewWindowIcon, PictureAsIcon, PlaylistAddStarCheckIcon, SearchIcon, SegmentIcon, ShoppingCartIcon, snackbarService, TransactionIcon, VehicleIcon, WarningRedIcon } from 'components/mui';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { cloneDeep } from 'lodash';
import { AddFieldModal } from '../data-fields/add-field-modal.component';
import { sortFields } from '../data-fields/utils';
export const AiUpload = props => {
  const {
    asCompany,
    companyFields,
    companyFieldsChange,
    flags
  } = useContext(UserStateContext);
  let fields, numRecords;
  const customerFields = useRef([]);
  const [uploadedFile, setUploadedFile] = useState();
  const [confirmUpload, setConfirmUpload] = useState(false);
  const [importSources, setImportSources] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uppy, setUppy] = useState();
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [uniqueIdModal, setUniqueIdModal] = useState(false);
  const [isSelectingOptions, setIsSelectingOptions] = useState(false);
  const [isSelectingFields, setIsSelectingFields] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(true);
  const [isSelectingUniqueID, setIsSelectingUniqueID] = useState(false);
  const [isViewingSummary, setIsViewingSummary] = useState(false);
  const [wizardStepCount, setWizardStepCount] = useState(0);
  const [loseStepDataWarning, setLoseStepDataWarning] = useState(false);
  const [resetWizardWarning, setResetWizardWarning] = useState(false);
  const [addNewFieldOpen, setAddNewFieldOpen] = useState(false);
  const [hoverIconColorButton1, setHoverIconColorButton1] = useState('#3898D9');
  const [hoverIconColorButton2, setHoverIconColorButton2] = useState('#3898D9');
  const [previewCSVData, setPreviewCSVData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newFieldValue, setNewFieldValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [customerAllFields, setCustomerAllFields] = useState([]);
  const [previewHeaders, setPreviewHeaders] = useState([]);
  const [fieldToMap, setFieldToMap] = useState('');
  const [displayFields, setDisplayFields] = useState({});
  const [useSavedMapping, setUseSavedMapping] = useState(false);
  const [segmentTypesMenuAnchorEl, setSegmentTypesMenuAnchorEl] = useState(null);
  const windowHeight = window.innerHeight;
  const [paperHeight, setPaperHeight] = useState('88vh');
  const [uniqueModels, setUniqueModels] = useState([]);
  const [savedMappings, setSavedMappings] = useState([]);
  const [saveConfig, setSaveConfig] = useState(false);
  const [configName, setConfigName] = useState('');
  const [updateConfig, setUpdateConfig] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [genAIMapping, setGenAIMapping] = useState({});
  const [tempRecords, setTempRecords] = useState([]);
  useEffect(() => {
    const updateHeight = () => {
      const viewportHeight = window.innerHeight;
      const calculatedHeight = viewportHeight * 0.88;
      setPaperHeight(calculatedHeight >= 950 ? '950px' : '88vh');
    };
    updateHeight();
    // Update height on window resize
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  // dummy data delete later but use now for testing
  //  to test savedConfigs and savedConfigs.length
  const savedConfigsTest = [{
    //  save uploadedFile to this object
  }];

  // to render the preview data table using the key as the column header
  const importResultsToTestDisplay = {
    records: [{
      'First Name': {
        model: 'customer',
        field: 'first_name'
      },
      'Last Name': {
        model: 'customer',
        field: 'last_name'
      },
      Amount: {
        model: 'transaction',
        field: 'amount'
      },
      Email: {
        model: 'customer',
        field: 'email'
      },
      Phone: {
        model: 'customer',
        field: 'phone'
      },
      Vehicle_status: {
        model: 'vehicle',
        field: 'vehicle'
      },
      Vehicle_make: {
        model: 'vehicle',
        field: 'vehicle_make'
      },
      Vehicle_model: {
        model: 'vehicle',
        field: 'vehicle_model'
      },
      Vehicle_year: {
        model: 'vehicle',
        field: 'vehicle_year'
      },
      Schedule: {
        model: 'schedule',
        field: 'schedule'
      },
      schedule_date: {
        model: 'schedule',
        field: 'schedule_date'
      },
      Cart: {
        model: 'cart',
        field: 'cart'
      },
      cart_status: {
        model: 'cart',
        field: 'cart_status'
      },
      cart_total: {
        model: 'cart',
        field: 'cart_total'
      },
      cart_transaction_date: {
        model: 'cart',
        field: 'cart_transaction_date'
      },
      Transaction: {
        model: 'transaction',
        field: 'transaction'
      },
      transaction_date: {
        model: 'transaction',
        field: 'transaction_date'
      },
      transaction_status: {
        model: 'transaction',
        field: 'transaction_status'
      },
      transaction_total: {
        model: 'transaction',
        field: 'transaction_total'
      },
      transaction_type: {
        model: 'transaction',
        field: 'transaction_type'
      }
    }]
  };
  <Icon name="custom-location_on" size={20} />;
  const segmentIncludedTypes = [{
    key: 'customer_refs',
    name: 'Customer',
    icon: CustomerIcon
  }, {
    key: 'customers_possessions_vehicles',
    name: 'Vehicle',
    icon: VehicleIcon
  }, {
    key: 'schedule',
    name: 'Schedule',
    icon: Calendar30DaysIcon
  }, {
    key: 'transactions',
    name: 'Transaction',
    icon: TransactionIcon
  }, {
    key: 'transactions_details',
    name: 'Transaction Details',
    icon: TransactionIcon
  }, {
    key: 'transactions_coupons',
    name: 'Coupon',
    icon: TransactionIcon
  }, {
    key: 'cart',
    name: 'Cart',
    icon: ShoppingCartIcon
  }, {
    key: 'locations',
    name: 'Location',
    icon: <Icon name="custom-location_on" size={20} />
  }, {
    key: 'customers_possessions_subscriptions',
    name: 'Subscription',
    icon: <Icon name="custom-subscriptions" size={20} />
  }];
  const makeOverflowTooltip = (content, length, bold) => {
    const contentLength = content?.length || content?.props?.children?.length;
    if (content) {
      return <Tooltip title={contentLength > length ? <> {content} </> : ''}>
          <Typography variant={bold ? 'tableHeader' : ''}>
            {contentLength > length ? <>{('' + (content.props?.children || content)).slice(0, length) + '...'}</> : <>{content}</>}
          </Typography>
        </Tooltip>;
    }
    return <Typography variant="nullData" color={'#BEBEBE'}>
        <span>--</span>{' '}
      </Typography>;
  };
  const LightTooltip = styled(({
    className,
    ...props
  }) => <Tooltip {...props} classes={{
    popper: className
  }} />)(({
    theme
  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 14,
      maxWidth: '312px',
      borderRadius: '16px',
      padding: '12px',
      lineHeight: '16px'
    }
  }));
  useEffect(() => {
    /**
     * Load company fields on mount
     */
    const companyId = asCompany.id;
    Promise.all([getCompanyFields(companyId)]).then(([{
      data: fields,
      data
    }]) => {
      setCustomerAllFields(data);
      console.log('data', data);
      const customerField = data.find(f => f.id === 'customer');
      const vehicleField = data.find(f => f.id === 'vehicle');
      const transactionField = data.find(f => f.id === 'transaction');
      const scheduleField = data.find(f => f.id === 'schedule');
      const cartField = data.find(f => f.id === 'cart');
      const locationField = data.find(f => f.id === 'location');
      const subscriptionField = data.find(f => f.id === 'subscription');
      const couponField = data.find(f => f.id === 'coupon_possession');
      const transactionDetailsField = data.find(f => f.id === 'transaction_detail');
      const transactionCouponsField = data.find(f => f.id === 'cart_coupon');
      const fieldOptions = [{
        customer: customerField?.fields?.map(f => f.name),
        vehicle: vehicleField?.fields?.map(f => f.name),
        transaction: transactionField?.fields.map(f => f.name),
        schedule: scheduleField?.fields?.map(f => f.name),
        cart: cartField.fields?.map(f => f.name),
        location: locationField?.fields?.map(f => f.name),
        subscription: subscriptionField?.fields?.map(f => f.name),
        coupon: couponField?.fields?.map(f => f.name),
        transaction_details: transactionDetailsField?.fields?.map(f => f.name),
        transaction_coupons: transactionCouponsField?.fields?.map(f => f.name)
      }];
      const temp = [...fieldOptions];
      setOptions(temp);
      setPreviewHeaders(customerField);
    });
    getAICSVMapping({
      company: asCompany.id
    }).then(({
      data
    }) => {
      setSavedMappings(data.results);
    });
  }, [asCompany]);
  useEffect(() => {
    let fileName = '';
    const customerModel = companyFields.find(model => model.id === 'customer');
    const allCustomerFields = get(customerModel, 'fields') || [];
    const filteredCustomerFields = allCustomerFields.filter(f => {
      return !constants.READ_ONLY_CUSTOMER_FIELDS.some(cf => cf === f.id.substr(0, cf.length));
    });
    customerFields.current = filteredCustomerFields;
    getCSVImportSources({
      company: asCompany.id
    }).then(({
      data
    }) => {
      setImportSources(data.map(r => r.source));
    });

    //TODO: fetch AI import sources?
    const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        maxTotalFileSize: 25000000,
        // Max file size 25mb
        allowedFileTypes: ['.csv']
      },
      disableThumbnailGenerator: true,
      showRemoveButtonAfterComplete: false,
      fileManagerSelectionType: 'files',
      allowMultipleUploads: false
    }).use(AwsS3Multipart, {
      companionUrl: API_URL + `/company-files/${asCompany.id}/upload`,
      parentFolder: 'csv/',
      createMultipartUpload,
      listParts,
      prepareUploadPart,
      completeMultipartUpload,
      abortMultipartUpload
    }).on('file-added', file => {
      setIsLoading(true);
      handleFile(file.data);
    }).on('upload-success', file => {
      if (!fields) {
        uppy.reset();
        snackbarService.popup({
          message: 'Unable to parse any headers from the CSV file. Please check the file and try again.',
          type: 'error'
        });
        return;
      }
      if (!numRecords) {
        uppy.reset();
        snackbarService.popup({
          message: 'CSV file does not contain any data. Please check the file and try again.',
          type: 'error'
        });
        return;
      }
      generateAI(file.name, uppy);
    });
    setUppy(uppy);
  }, []);
  const generateAI = (fileName, uppy) => {
    setIsLoading(true);
    // GENERATE THE AI MAPPING BEFORE MOVING FORWARD
    generateAICSVMapping({
      company_id: asCompany.id,
      filename: fileName
    }).then(({
      data
    }) => {
      setGenAIMapping(data);
      const records = {};

      // try to autofill any values that match
      const tempMap = {};
      // check options for any field matches for the field name and the model
      options.forEach(option => {
        Object.keys(option).forEach(model => {
          option[model].forEach(field => {
            tempMap[field.toUpperCase()] = {
              val: field,
              model
            };
          });
        });
      });
      fields.forEach(field => {
        const upperField = field.toUpperCase();
        if (tempMap[upperField]) {
          records[field] = {
            [field]: tempMap[upperField].val,
            model: tempMap[upperField].model
          };
        }
      });
      const newFileData = {
        company: asCompany.id,
        filename: fileName,
        total_records: numRecords,
        source: 'CRM Upload',
        type: 'customer',
        other: '',
        fields,
        records: data.records,
        new_fields: {},
        segment_option: 'new',
        new_segment: fileName,
        segment_id: ''
      };
      setUploadedFile(newFileData);
      setIsLoading(false);
    }).catch(e => {
      uppy.reset();
      snackbarService.popup({
        message: 'An error occurred while generating the AI mapping. Please check your file and try again.',
        type: 'error'
      });
      setIsLoading(false);
      return;
    });
  };

  // useEffect for testing to see uploadedFIle
  useEffect(() => {
    if (uploadedFile) {
      console.log('uploadedFile useEffect', uploadedFile);
      if (tempRecords) {
        console.log('tempRecords', tempRecords);
      }
      if (uploadedFile.records) {
        //
        const tempRecords = [];
        uploadedFile.records.forEach(record => {
          const stream = record.stream;
          const properties = record.properties;
          Object.keys(properties).forEach(key => {
            const newRecord = {
              mapped_field: properties[key].key,
              model: stream,
              type: 'record',
              source_key: key
            };
            tempRecords.push(newRecord);
          });
        });
        setTempRecords(tempRecords);
        console.log('tempRecords', tempRecords);
      }
    }
  }, [uploadedFile]);
  const handleFile = file => {
    const fr = new FileReader();
    fr.onload = function () {
      const data = Papa.parse(fr.result);
      const rows = data.data.filter(r => r.join('').trim() !== '');
      fields = rows.shift();
      numRecords = Math.max(0, rows.length);
    };
    try {
      fr.readAsText(file);
    } catch (e) {
      // For some unknown reason this throws an error but it still works *facepalm*
    }
  };

  //TODO: this aint gonna work, need to change it up for the new maps and also use the API for AICSV stuff somewhere too
  const actuallyUpload = async () => {
    setIsUploading(true);

    // TODO: if they want to save configuration then run this
    if (saveConfig) {
      try {
        await createAICSVMapping({
          company_id: asCompany.id,
          mappings: uploadedFile.records,
          name: configName
        });
      } catch (e) {
        snackbarService.popup({
          message: 'An error occurred while saving the configuration. Please try again.',
          type: 'error'
        });
        return;
      }
    }
    if (updateConfig) {
      try {
        await updateAICSVMapping({
          company_id: asCompany.id,
          data: uploadedFile
        });
      } catch (e) {
        snackbarService.popup({
          message: 'An error occurred while updating the configuration. Please try again.',
          type: 'error'
        });
      }
      return;
    }
    try {
      await importCSV(uploadedFile);
      props.history.push({
        pathname: '/imports'
      });
    } catch (error) {
      snackbarService.popup({
        message: 'An error occurred while importing the CSV file. Please try again.',
        type: 'error'
      });
      return;
    } finally {
      setIsUploading(false);
    }
  };
  const handleMapFieldTempRecords = (e, field, streamName, rowIndex, streamIndex) => {
    // in tempRecords, change the mapped_field to the new value
    const newValue = e.target.value;
    const updatedRecords = [...tempRecords];
    updatedRecords[rowIndex].mapped_field = newValue;
    setTempRecords(updatedRecords);
  };

  // mapfield needs to send the key instead of just customer/vehicle. might need to sent customer_refs instead of customer and customer_possessions_vehicles instead of vehicle
  const handleMapFieldChange = (e, field, streamName, rowIndex, streamIndex) => {
    const newValue = e.target.value;
    mapField(field, newValue, streamName, rowIndex, streamIndex);
  };
  const mapField = (field, val, streamName, rowIndex, streamIndex) => {
    const updatedRecords = [...uploadedFile.records];

    // let streamIndexToUse = uploadedFile.records.findIndex((record) => record.stream === streamIndex);
    // let streamIndexToUse = streamName;

    // Update the specific field within the specific stream
    if (!updatedRecords[streamIndex].properties) {
      updatedRecords[streamIndex].properties = {};
    }
    updatedRecords[streamIndex].properties[field] = {
      key: val,
      type: 'record'
    };
    setUploadedFile({
      ...uploadedFile,
      records: updatedRecords
    });
  };
  const wizardStepHandler = step => {
    /**
     *
     * wizard step 0 is uploading file
     * wizard step 1 is verifying or selecting the mapped CRM fields we found
     * wizard step 2 is selecting the unique id
     * wizard step 3 is previewing the data
     * wizard step 4 is selecting the segment and the inclusion type
     * wizard step 5 is uploading the data
     */
    if (!uploadedFile) return;
    if (step === 5) {
      setConfirmUpload(true);
      return;
    }

    // generate preview on step 3
    if (step === 3) {
      // write the changes that were made to tempRecords to uploadedFile.records
      // use the model as the stream, the mapped_field as the key, and the source_key as the field
      setIsLoading(true);
      const newRecords = [];
      tempRecords.forEach(record => {
        const stream = record.model;
        const key = record.mapped_field;
        const field = record.source_key;
        const newRecord = {
          stream,
          properties: {
            [key]: {
              key,
              type: 'record'
            }
          }
        };
        newRecords.push(newRecord);
      });
      setUploadedFile({
        ...uploadedFile,
        records: newRecords
      });
      setIsLoading(false);
    }
    const steps = {
      0: {
        count: 0,
        uploading: true,
        selectingFields: false,
        selectingUniqueID: false,
        viewingSummary: false,
        selectingOptions: false
      },
      1: {
        count: 1,
        uploading: false,
        selectingFields: true,
        selectingUniqueID: false,
        viewingSummary: false,
        selectingOptions: false
      },
      2: {
        count: 2,
        uploading: false,
        selectingFields: false,
        selectingUniqueID: true,
        viewingSummary: false,
        selectingOptions: false
      },
      3: {
        count: 3,
        uploading: false,
        selectingFields: false,
        selectingUniqueID: false,
        viewingSummary: true,
        selectingOptions: false
      },
      4: {
        count: 4,
        uploading: false,
        selectingFields: false,
        selectingUniqueID: false,
        viewingSummary: false,
        selectingOptions: true
      }
    };
    const currentStep = steps[step];
    if (currentStep) {
      setWizardStepCount(currentStep.count);
      setIsUploadingFile(currentStep.uploading);
      setIsSelectingFields(currentStep.selectingFields);
      setIsSelectingUniqueID(currentStep.selectingUniqueID);
      setIsViewingSummary(currentStep.viewingSummary);
      setIsSelectingOptions(currentStep.selectingOptions);
    }
  };
  const handleSegmentTypesClose = (e, field, originalStreamName, streamIndex, fieldIndex) => {
    tempRecords.forEach(record => {
      if (record.key === field && record.model === originalStreamName) {
        record.model = e.target.value;
      }
    });
    setTempRecords([...tempRecords]);
    setSegmentTypesMenuAnchorEl(null);
  };
  const getDataModelValue = field => {
    let model = '';
    uploadedFile?.records?.forEach(record => {
      if (record.properties[field]) {
        model = record.stream;
      }
    });
    if (model === 'customer_ref' || model === 'customer_refs') {
      return 'customer';
    } else if (model === 'location' || model === 'locations') {
      return 'location';
    } else if (model.includes('vehicle')) {
      return 'vehicle';
    } else if (model.includes('subscription')) {
      return 'subscription';
    } else if (model.includes('transaction')) {
      return 'transaction';
    } else if (model.includes('cart')) {
      return 'cart';
    } else if (model.includes('schedule')) {
      return 'schedule';
    }
  };
  const getModelDisplayValue = model => {
    const segment = segmentIncludedTypes.find(segment => segment.key === model);
    return segment ? segment.name : '';
  };
  const getSegmentIncludedValue = field => {
    for (const record of uploadedFile.records) {
      if (record.properties.hasOwnProperty(field)) {
        if (record.stream === 'customer_ref') {
          return 'customer';
        } else if (record.stream === 'location') {
          return 'location';
        } else if (record.stream.includes('vehicle')) {
          return 'vehicle';
        } else if (record.stream.includes('subscription')) {
          return 'subscription';
        } else if (record.stream.includes('transaction')) {
          return 'transaction';
        } else if (record.stream.includes('cart')) {
          return 'cart';
        } else if (record.stream.includes('schedule')) {
          return 'schedule';
        }
      }
    }
    return segmentIncludedTypes[0].key;
  };
  const getFilteredOptions = model => {
    /**
     * Filter options based on the selected model
     */
    const modelOptions = options.find(option => Object.keys(option).includes(model));
    return modelOptions ? modelOptions[model] : [];
  };
  const handleUniqueID = (stream, newValue) => {
    const recordIndex = uploadedFile.records.findIndex(record => record.stream === stream);
    if (recordIndex === -1) {
      return;
    }
    const record = uploadedFile.records[recordIndex];

    // If the newValue is empty, remove the entity_ref from the properties
    if (!newValue || newValue.length === 0) {
      delete record.properties.entity_ref;
      setUploadedFile({
        ...uploadedFile,
        records: [...uploadedFile.records]
      });
      return;
    }
    const pieces = newValue.map(piece => {
      return {
        key: piece.trim(),
        type: 'record'
      };
    });
    record.properties.entity_ref = {
      type: 'hash',
      object: {
        type: 'join',
        pieces
      }
    };
    setUploadedFile({
      ...uploadedFile,
      records: [...uploadedFile.records]
    });
  };

  //TODO: need to select a mapping configuration from the savedMappings array and then set it to uploadedFile?
  const handleSelectMappedConfig = configName => {
    const selectedConfig = savedMappings.find(config => config.name === configName);
    // const newMaps = { ...uploadedFile.maps };
    // Object.keys(selectedConfig.fields).forEach((field) => {
    //   newMaps[field] = {
    //     [field]: selectedConfig.fields[field],
    //     model: 'customer', // probably need to get the model too, shouldnt default to customer
    //   };
    // });

    // setUploadedFile({
    //   ...uploadedFile,
    //   maps: newMaps,
    // });
    /**
     * if any record in properties has a type of config instead of record, then map all the fields that have a type of config. if no type, then set type as record
     * also set the records.properties.entity_ref as the entity_ref
     *
     */

    // map the records to the fields from the config and set the model to the model from the config
    const newRecords = {
      ...uploadedFile.records
    };
    Object.keys(selectedConfig.fields).forEach(field => {
      // do the mapping based upon singer recommendations
    });
    console.log('code me', selectedConfig);
  };
  return <Scoped css={styles}>
      <div className="wrapper">
        <PageHeaderMui name="Import Data" icon={<Icon name="custom-exit_to_app" size={34} />} />
        <Paper sx={{
        position: 'relative',
        height: paperHeight,
        // 904px or 88vh..
        width: '874px',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        backgroundColor: '#fff',
        marginTop: '20px',
        marginBottom: '20px'
      }}>
          <Stack direction="row" sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pb: '10px'
        }}>
            <Box>
              <Typography sx={{
              fontSize: '20px'
            }}>
                {wizardStepCount === 4 ? 'Import Data Overview' : wizardStepCount === 1 ? 'Data Mapping' : wizardStepCount === 2 ? 'Unique ID' : wizardStepCount === 3 ? 'Preview Import' : 'Import Configuration'}
              </Typography>
            </Box>
            <Box sx={{
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
              <IconButton onClick={() => props.history.push('/imports')}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
          {wizardStepCount === 4 ? null : <Divider id="top-wizard-divider" sx={{
          ml: '-20px',
          mr: '-20px'
        }} />}

          {wizardStepCount === 0 && <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: '20px'
        }}>
              <CsvImportWizardIcon />
            </Box>}

          <Box sx={{
          pt: '20px'
          // pr: '80px',
        }}>
            <Typography sx={{
            textAlign: 'justify'
          }}>
              {wizardStepCount === 3 ? `Here's a preview of how your data will be displayed in Cinch.
                  This table showcases the top 25 records from your CSV import, providing insight into the data layout. If you happen to see customer emails under the First Name column, it's a good opportunity to adjust your data mapping settings.` : wizardStepCount === 2 ? <>
                  If your CSV import includes a unique identifier, be sure to use a unique ID. This identifier should only appear once in
                  your data. When applied, Cinch will check if the value already exists in your database and update the existing record
                  instead of creating a new one.
                  <br />
                  For example, the most common unique ID is an email address. By selecting "Email" from the dropdown menu, Cinch will verify
                  if the email addresses in your CSV are already in Cinch and update the existing records accordingly.
                  <br />
                  <br />
                  Additionally, you can also select a combination of multiple fields to use as a Unique ID.
                </> : wizardStepCount === 1 ? `Data mapping involves matching the column headers in your CSV file with the corresponding data fields in Cinch. This guarantees that the data from your CSV file is accurately imported into the right sections in Cinch, enabling seamless data integration and management.` : wizardStepCount === 4 ? `Congratulations on successfully uploading your CSV file! On this page, you have the option to label the source of the CSV file. For instance, if it's a list of customers who signed up from an event, you can create a custom source named 'Event Name 12-31-24 Sign up.' Additionally, you can choose to add these customers to a new or existing static segment.` : `Welcome to the Import Data Wizard. We will help you to upload your CSV file and import your customer data into Cinch. To get started, upload your CSV file by clicking the 'browse files' button highlighted in blue. Once uploaded, you may nagivate to the next step in the process by clicking the "Next" button located at the bottom.`}
            </Typography>
          </Box>

          {isUploadingFile && <Box id="content-container-0" sx={{
          height: '591px'
        }}>
              <Stack direction="row" sx={{
            pt: '45.5px',
            pb: paperHeight >= 950 ? '149px' : null
          }}>
                <Stack direction="column" spacing={3} sx={{
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'left',
              pr: '117px'
            }}>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}>
                    <Radio sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22
                  }
                }} checked={!useSavedMapping} defaultChecked onChange={() => {
                  setUseSavedMapping(false);
                }} />
                    <Typography sx={{
                  cursor: 'default'
                }}>Upload new file</Typography>
                  </Box>

                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}>
                    <Radio sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22
                  }
                }} checked={useSavedMapping} disabled={savedConfigsTest.length === 0} // TODO: change to savedConfigs after testing
                onChange={() => {
                  setUseSavedMapping(true);
                }} />
                    {/* // TODO: change to fetch savedConfigs in a useEffect on load and then save as savedConfigs  */}
                    <Box sx={{
                  pr: '5px',
                  opacity: savedConfigsTest.length === 0 ? 0.5 : 1,
                  cursor: 'default'
                }}>
                      <Typography>Use saved mapping configuration</Typography>
                    </Box>
                    <LightTooltip title={<Grid container>
                          <Grid item>
                            <Typography variant="caption" sx={{
                      fontWeight: 'bold'
                    }}>
                              Mapping Configuration
                            </Typography>
                            <br />
                            <Typography variant="caption" sx={{
                      textAlign: 'justify'
                    }}>
                              If you have previously saved a mapping configuration, you can use it to import your CSV file. This will save
                              you time and effort by automatically mapping the fields in your CSV file to the corresponding fields in Cinch.
                            </Typography>
                            <br />
                          </Grid>
                        </Grid>} placement="bottom-start">
                      <Box>
                        <HelpIcon fill={'#53A6D6'} />
                      </Box>
                    </LightTooltip>
                  </Box>
                </Stack>
                <Stack sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}>
                  <Box sx={{
                width: '300px'
              }}>
                    {useSavedMapping && !selectedValue && <Typography variant="caption" color="error" sx={{
                  mb: 1,
                  pl: '15px'
                }}>
                        Select configuration to continue
                      </Typography>}
                  </Box>
                  {useSavedMapping &&
              // search bar input adornment to the start of the text input
              // also make this requried with error message if they checked the radio button (useSavedMapping) and have not selected a saved mapping yet
              // TODO: disable the next button until a saved mapping is selected
              <>
                      <Autocomplete id="autocomplete-saved-mapping" sx={{
                  opacity: 0.5,
                  '&.Mui-focused': {
                    opacity: 1
                  }
                }} options={savedMappings} onChange={e => {
                  // fetch the selected config from the savedConfigs array and set it to uploadedFile
                  handleSelectMappedConfig(e.target.value);
                }} renderInput={params => <TextField {...params} InputProps={{
                  ...params.InputProps,
                  startAdornment: <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                }} placeholder="Search configurations" variant="outlined" fullWidth error={useSavedMapping && !selectedValue} sx={{
                  width: '300px',
                  borderRadius: '36px'
                }} />} />
                    </>}
                </Stack>
              </Stack>
              {uppy && !useSavedMapping ? <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pt: '15px',
            pb: '85px'
          }}>
                  <Dashboard width={370} height={165} uppy={uppy} />
                </Box> : <Box sx={{
            maxHeight: '418px',
            overflowY: 'auto'
          }}>
                  {/*TODO: Add sort by date created, might be worth using enhancedTable but also this is a simple table */}
                  <Table sx={{
              minWidth: 650
            }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Configuration Name</TableCell>
                        <TableCell align="right">Created By</TableCell>
                        <TableCell align="right">Date Created</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {savedMappings.length > 0 ? savedMappings.map(config => <TableRow key={config.name} onClick={e => {
                  handleSelectMappedConfig(config.name);
                }}>
                            <TableCell component="th" scope="row">
                              {config.name}
                            </TableCell>
                            <TableCell align="right">{config.created_by}</TableCell>
                            <TableCell align="right">{config.created_when}</TableCell>
                          </TableRow>) : <TableRow>
                          <TableCell colSpan={3} align="center" sx={{
                    cursor: 'default'
                  }}>
                            No configurations found
                          </TableCell>
                        </TableRow>}
                    </TableBody>
                  </Table>
                </Box>}
            </Box>}
          <>
            {isSelectingFields && uploadedFile && <Box id="content-container-4" sx={{
            height: '740px'
          }}>
                <Stack direction="row" sx={{
              pt: '20px'
            }}>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                pb: '20px',
                pr: '190px',
                textWrap: 'nowrap'
              }}>
                    <Typography sx={{
                  textAlign: 'justify',
                  pr: '10px'
                }}>
                      CSV Field
                    </Typography>
                    <LightTooltip title={<Grid container>
                          <Grid item>
                            <Typography variant="caption" sx={{
                      fontWeight: 'bold'
                    }}>
                              CSV Field
                            </Typography>
                            <br />
                            <Typography variant="caption" sx={{
                      textAlign: 'justify'
                    }}>
                              These are the fields in your CSV file.
                            </Typography>
                            <br />
                          </Grid>
                        </Grid>} placement="top-start">
                      <Box>
                        <HelpIcon fill={'#53A6D6'} />
                      </Box>
                    </LightTooltip>
                  </Box>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                pb: '20px',
                pr: '127px',
                textWrap: 'nowrap'
              }}>
                    <Typography sx={{
                  textAlign: 'justify',
                  pr: '10px'
                }}>
                      Data Model
                    </Typography>
                    <LightTooltip title={<Grid container>
                          <Grid item>
                            <Typography variant="caption" sx={{
                      fontWeight: 'bold'
                    }}>
                              Data Model
                            </Typography>
                            <br />
                            <Typography variant="caption" sx={{
                      textAlign: 'justify'
                    }}>
                              These are the data models that will be used with the Cinch Data Fields to map your CSV fields to.
                            </Typography>
                            <br />
                          </Grid>
                        </Grid>} placement="top-start">
                      <Box>
                        <HelpIcon fill={'#53A6D6'} />
                      </Box>
                    </LightTooltip>
                  </Box>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                pb: '20px',
                textWrap: 'nowrap'
              }}>
                    <Typography sx={{
                  textAlign: 'justify',
                  pr: '10px'
                }}>
                      Cinch Data Field
                    </Typography>
                    <LightTooltip title={<Grid container>
                          <Grid item>
                            <Typography variant="caption" sx={{
                      fontWeight: 'bold'
                    }}>
                              Cinch Data Field
                            </Typography>
                            <br />
                            <Typography variant="caption" sx={{
                      textAlign: 'justify'
                    }}>
                              These are the data fields that we will map the CSV fields to so that you can use your customer data
                              effectively.
                            </Typography>
                            <br />
                          </Grid>
                        </Grid>} placement="top-start">
                      <Box>
                        <HelpIcon fill={'#53A6D6'} />
                      </Box>
                    </LightTooltip>
                  </Box>
                </Stack>
                <Divider sx={{
              ml: '-20px',
              mr: '-20px'
            }} />

                {/* TODO: figure outa variable height.. */}
                <Box id="scrollable-data-fields" sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              height: paperHeight >= 950 ? '560px' : '470px',
              p: 0,
              pt: '10px'
            }}>
                  {/* dynamic CSV field data column*/}
                  {uploadedFile && <Box>
                      {/* use tempRecords instead */}
                      {tempRecords.map((record, index) => <Box key={index}>
                          <Stack id="stack-data-field-entire-row" direction="row">
                            <Stack id={`stack-csv-field-${index}`} direction="row" key={`${index}`} sx={{
                      py: '10px'
                    }}>
                              <Box>
                                <TextField sx={{
                          width: '223px',
                          backgroundColor: '#f9f9f9',
                          borderRadius: '14px'
                        }} id={`textfield-dynamic-${record.source_key}`} variant="outlined" autoComplete="off" fullWidth inputProps={{
                          readOnly: true,
                          disabled: true
                        }} value={record.source_key} />
                              </Box>
                              <Box sx={{
                        opacity: record.source_key && record.source_key !== 'Please select a field' ? 1 : 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        px: '20px'
                      }}>
                                <ArrowCooldownIcon />
                              </Box>
                            </Stack>
                            <Stack id={`stack-cinch-data-field-${index}`} direction="row" key={`${index}`} sx={{
                      py: '10px'
                    }} spacing={2}>
                              <Box>
                                <Select labelId={`label-segment-inclusion-${index}`} id={`select-segment-inclusion-${index}`} options={segmentIncludedTypes} sx={{
                          width: '223px',
                          height: '55px'
                        }} onChange={e => {
                          handleSegmentTypesClose(e, record.source_key, record.model, index);
                        }} value={record.model} renderValue={selected => <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px'
                        }}>
                                      {selected === 'customer' ? <CustomersIcon size={24} sx={{
                            pl: '15px'
                          }} /> : selected === 'vehicle' ? <VehicleIcon size={24} sx={{
                            pl: '15px'
                          }} /> : selected === 'schedule' ? <Calendar30DaysIcon size={24} sx={{
                            pl: '15px'
                          }} /> : selected === 'transaction' ? <TransactionIcon size={24} sx={{
                            pl: '15px'
                          }} /> : selected === 'cart' ? <ShoppingCartIcon size={24} sx={{
                            pl: '15px'
                          }} /> : <CustomersIcon size={24} sx={{
                            pl: '15px'
                          }} />}
                                      {/* use the .name to display the uppercase name  */}
                                      {/* {selected.charAt(0).toUpperCase() + selected.slice(1)} */}
                                      {getModelDisplayValue(selected)}
                                    </Box>}>
                                  {segmentIncludedTypes.map(type => <MenuItem sx={{
                            pl: '15px'
                          }} value={type.key}>
                                      {type.key === 'customer' ? <CustomersIcon size={24} sx={{
                              pl: '15px'
                            }} /> : type.key === 'vehicle' ? <VehicleIcon size={24} sx={{
                              pl: '15px'
                            }} /> : type.key === 'schedule' ? <Calendar30DaysIcon size={24} sx={{
                              pl: '15px'
                            }} /> : type.key === 'transaction' ? <TransactionIcon size={24} sx={{
                              pl: '15px'
                            }} /> : type.key === 'cart' ? <ShoppingCartIcon size={24} sx={{
                              pl: '15px'
                            }} /> : <CustomersIcon size={24} sx={{
                              pl: '15px'
                            }} />}
                                      {type.name}
                                    </MenuItem>)}
                                </Select>
                              </Box>
                              <Box>
                                <Select labelId={`label-cinch-data-field-${index}`} id={`select-cinch-data-field-${index}`} onChange={e =>
                        // handleMapFieldChange(e, record.key, getSegmentIncludedValue(record.key), record.model, index)
                        handleMapFieldTempRecords(e, record.mapped_field, record.model, index)}
                        // value={record.key || 'None'}
                        value={record.mapped_field || 'None'} sx={{
                          width: '223px',
                          height: '55px'
                        }} MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: '700px'
                            }
                          }
                        }}>
                                  <MenuItem value="None">
                                    <em>None</em>
                                  </MenuItem>
                                  {getFilteredOptions(getDataModelValue(record.source_key)).map(option => <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>)}
                                </Select>
                              </Box>
                            </Stack>
                            <Box sx={{
                      opacity: record.mapped_field && record.mapped_field !== 'Please select a field' ? 1 : 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      px: '20px'
                    }}>
                              <CheckIcon fill={'#3EB87B'} />
                            </Box>
                          </Stack>
                        </Box>)}
                    </Box>}
                </Box>
              </Box>}
          </>
          <>
            {/* TODO: change this to last step before submitting so the user can specify segment details and choose an inclusion type */}
            {isSelectingOptions && uploadedFile && <Box id="content-container-1" sx={{
            height: '740px'
          }}>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              py: '20px'
            }}>
                  <Typography sx={{
                textAlign: 'justify',
                pr: '10px'
              }}>
                    File info
                  </Typography>
                  <LightTooltip title={<Grid container>
                        <Grid item>
                          <Typography variant="caption" sx={{
                    fontWeight: 'bold'
                  }}>
                            File info
                          </Typography>
                          <br />
                          <Typography variant="caption" sx={{
                    textAlign: 'justify'
                  }}>
                            This is the information about the file you uploaded.
                          </Typography>
                          <br />
                        </Grid>
                      </Grid>} placement="top-start">
                    <Box>
                      <HelpIcon fill={'#53A6D6'} />
                    </Box>
                  </LightTooltip>
                </Box>
                <Stack direction="row" spacing={3}>
                  <Box>
                    <TextField sx={{
                  width: '389px'
                }} id="textfield-filename" label="Filename" variant="outlined" autoComplete="off" fullWidth value={uploadedFile.filename} disabled />
                  </Box>
                  <Box>
                    <TextField sx={{
                  width: '389px'
                }} id="textfield-number-of-rows" label="Number of rows" variant="outlined" autoComplete="off" fullWidth value={uploadedFile.total_records} disabled />
                  </Box>
                </Stack>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              pb: '20px',
              pt: '20px'
            }}>
                  <Typography sx={{
                textAlign: 'justify',
                pr: '10px'
              }}>
                    File source
                  </Typography>
                  <LightTooltip title={<Grid container>
                        <Grid item>
                          <Typography variant="caption" sx={{
                    fontWeight: 'bold'
                  }}>
                            File Source
                          </Typography>
                          <br />
                          <Typography variant="caption" sx={{
                    textAlign: 'justify'
                  }}>
                            Specify which source the file came from. This is important for tracking and reporting purposes.
                          </Typography>
                          <br />
                        </Grid>
                      </Grid>} placement="top-start">
                    <Box>
                      <HelpIcon fill={'#53A6D6'} />
                    </Box>
                  </LightTooltip>
                </Box>
                <Stack direction="row" spacing={3}>
                  <Box>
                    <Select defaultValue={uploadedFile.source} onChange={e => setUploadedFile({
                  ...uploadedFile,
                  source: e.target.value
                })} variant="outlined" sx={{
                  width: '389px'
                }}>
                      <MenuItem value="CRM Upload">CRM Upload</MenuItem>
                      <MenuItem value="Other">Other (specify)</MenuItem>
                      <MenuItem value="None">None</MenuItem>
                    </Select>
                  </Box>
                  {uploadedFile.source === 'Other' && <Box>
                      <TextField sx={{
                  width: '389px'
                }} id="textfield-other" error={uploadedFile.source === 'Other' && uploadedFile.other.trim() === ''} helperText={uploadedFile.source === 'Other' && uploadedFile.other.trim() === '' ? 'Please specify the source' : ''} label="Other*" variant="outlined" autoComplete="off" fullWidth defaultValue={uploadedFile.other} onChange={e => setUploadedFile({
                  ...uploadedFile,
                  other: e.target.value
                })} />
                    </Box>}
                </Stack>
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              pb: '20px',
              pt: '20px'
            }}>
                  <Typography sx={{
                textAlign: 'justify',
                pr: '10px'
              }}>
                    Add to segment
                  </Typography>
                  <LightTooltip title={<Grid container>
                        <Grid item>
                          <Typography sx={{
                    fontWeight: 'bold'
                  }}>Add to segment</Typography>
                          <br />
                          <Typography sx={{
                    textAlign: 'justify'
                  }}>
                            Choose whether to add the customers in this file to a static segment. You can create a new segment or select an
                            existing one. If you don't want to add customers to a segment, select "None". By adding customers to a segment,
                            you can easily target them in future campaigns.
                          </Typography>
                          <br />
                        </Grid>
                      </Grid>} placement="top-start">
                    <Box>
                      <HelpIcon fill={'#53A6D6'} />
                    </Box>
                  </LightTooltip>
                </Box>
                <Stack direction="row" spacing={3}>
                  <Box>
                    <Select value={uploadedFile.segment_option} onChange={e => setUploadedFile({
                  ...uploadedFile,
                  segment_option: e.target.value
                })} variant="outlined" sx={{
                  width: '389px'
                }}>
                      <MenuItem value="new">New Static Segment</MenuItem>
                      <MenuItem value="select">Select Segment</MenuItem>
                      <MenuItem value="None">None</MenuItem>
                    </Select>
                  </Box>
                  <Box>
                    {uploadedFile.segment_option === 'new' ? <TextField key="new-segment" sx={{
                  width: '389px'
                }} id="textfield-new-segment" label="Static Segment" variant="outlined" autoComplete="off" fullWidth value={uploadedFile.new_segment} onChange={e => setUploadedFile({
                  ...uploadedFile,
                  new_segment: e.target.value
                })} /> : uploadedFile.segment_option === 'select' ? <DynamicTypeAheadMui key="select-segment" sx={{
                  width: '389px'
                }} getItems={getSegments} getItemsFilters={{
                  time_dynamic_segment: false,
                  company: asCompany.id
                }} placeholder="Choose Customer Segment" displayProperty="name" keyProperty="id" defaultValue={uploadedFile.segment_id} onChange={val => {
                  setUploadedFile({
                    ...uploadedFile,
                    segment_id: val ? val.id : '',
                    new_segment: ''
                  });
                }} /> : <></>}
                  </Box>
                </Stack>
                <Stack direction="row" spacing={3} sx={{
              pt: '67px'
            }}>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '389px'
              }}>
                    <Checkbox sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 22
                  }
                }} checked={saveConfig} onChange={e => setSaveConfig(e.target.checked)} />
                    {/* // TODO: change to fetch savedConfigs in a useEffect on load and then save as savedConfigs  */}
                    <Box sx={{
                  pr: '5px',
                  opacity: savedConfigsTest.length === 0 ? 0.5 : 1,
                  cursor: 'default'
                }}>
                      <Typography>Save this mapping configuration</Typography>
                    </Box>
                    <LightTooltip title={<Grid container>
                          <Grid item>
                            <Typography variant="caption" sx={{
                      fontWeight: 'bold'
                    }}>
                              Save Mapping Configuration
                            </Typography>
                            <br />
                            <Typography variant="caption" sx={{
                      textAlign: 'justify'
                    }}>
                              If you would like to save this mapping configuration for future use, check this box. And next time you upload
                              a CSV file, you can use this configuration to automatically map the fields in your CSV file.
                            </Typography>
                            <br />
                          </Grid>
                        </Grid>} placement="bottom-start">
                      <Box>
                        <HelpIcon fill={'#53A6D6'} />
                      </Box>
                    </LightTooltip>
                  </Box>
                  <Box sx={{
                height: '50px'
              }}>
                    <TextField sx={{
                  width: '389px',
                  visibility: saveConfig ? 'visible' : 'hidden'
                }} id="textfield-save-config"
                // label="Configuration Name"
                placeholder="Configuration Name" variant="outlined" autoComplete="off" fullWidth error={configName.trim() === '' || configName.length > 100 || savedMappings.some(config => config.name === configName)} helperText={configName.trim() === '' ? 'Please enter a name for the configuration' : configName.length > 100 ? 'Name must be less than 100 characters' : configName === savedMappings.find(config => config.name === configName) ? 'Name already exists' : ''} value={configName} onChange={e => setConfigName(e.target.value)} />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={3} sx={{
              pt: '10px'
            }}>
                  {/* <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      width: '389px',
                    }}
                   >
                    <Checkbox
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                      checked={updateConfig}
                      onChange={(e) => setUpdateConfig(e.target.checked)}
                    />
                    <Box sx={{ pr: '5px', opacity: savedConfigsTest.length === 0 ? 0.5 : 1, cursor: 'default' }}>
                      <Typography>Update this mapping configuration</Typography>
                    </Box>
                    <LightTooltip
                      title={
                        <Grid container>
                          <Grid item>
                            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                              Update Mapping Configuration
                            </Typography>
                            <br />
                            <Typography variant="caption" sx={{ textAlign: 'justify' }}>
                              If you would like to update this mapping configuration, check this box. And next time you upload a CSV file,
                              you can use this configuration to automatically map the fields in your CSV file.
                              <br />
                              <br />
                              This will overwrite the existing configuration.
                            </Typography>
                            <br />
                          </Grid>
                        </Grid>
                      }
                      placement="bottom-start"
                    >
                      <Box>
                        <HelpIcon fill={'#53A6D6'} />
                      </Box>
                    </LightTooltip>
                   </Box> */}
                </Stack>
              </Box>}
          </>

          <>
            {isSelectingUniqueID && uploadedFile && <Stack id="content-container-2" direction="column" spacing={3} sx={{
            pt: '31px',
            pb: '32px',
            height: '676px'
          }}>
                <Grid container spacing={3}>
                  {Object.entries(uploadedFile.records).map(([key, record], i) => <Grid item xs={6} key={i}>
                      <React.Fragment key={i}>
                        <Box>
                          <Typography sx={{
                      p: '5px'
                    }}>{segmentIncludedTypes.find(type => type.key === record.stream)?.name}</Typography>
                        </Box>
                        <Box>
                          <Autocomplete freeSolo multiple id={`autocomplete-unique-id-${record.stream}`} options={Array.isArray(uploadedFile.fields) ? [...uploadedFile.fields] : []} onChange={(event, newValue) => handleUniqueID(record.stream, newValue)} value={record.properties.entity_ref?.object?.pieces.map(piece => piece.key) || []} renderInput={params => <TextField label="Unique ID" {...params} sx={{
                      width: '345px'
                    }} variant="outlined" autoComplete="off" fullWidth />} />
                        </Box>
                      </React.Fragment>
                    </Grid>)}
                </Grid>
              </Stack>}
          </>
          <>
            {/* TODO: when user clicks on the model, show the preview data for that specific model only */}
            {isViewingSummary && uploadedFile && previewCSVData ? <Box id="content-container-3" sx={{
            height: '740px'
          }}>
                {/* this box lets you switch between each model to preview the data being imported */}
                <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
                  {/* <Stack
                    sx={{ px: '20px', pt: '20px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                    spacing="15px"
                    direction={'row'}
                   >
                    {segmentIncludedTypes.map(
                      (type) =>
                        uniqueModels.includes(type.key) && (
                          <Box key={type.key} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MUIButton
                              variant="contained"
                              sx={{
                                height: '30px',
                                width: '250px',
                                backgroundColor: type.key === 'vehicle' ? '#fff' : undefined,
                                color: type.key === 'vehicle' ? '#1D252D' : undefined,
                                ':hover': {
                                  backgroundColor: type.key === 'vehicle' ? '#DAEFFF' : undefined,
                                },
                              }}
                              onClick={() => {
                                // click model handler
                                // previewModelDataHandler(type.key);
                              }}
                            >
                              <Box sx={{ pr: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <type.icon />
                              </Box>
                              {`${type.name} Data`}
                            </MUIButton>
                          </Box>
                        ),
                    )}
                    {uniqueModels.map((model) => (
                      <Box key={model} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <MUIButton
                          variant="contained"
                          sx={{
                            height: '30px',
                            width: '250px',
                            backgroundColor: model === 'vehicle' ? '#fff' : undefined,
                            color: model === 'vehicle' ? '#1D252D' : undefined,
                            ':hover': {
                              backgroundColor: model === 'vehicle' ? '#DAEFFF' : undefined,
                            },
                          }}
                          onClick={() => {
                            // Handle model-specific data click
                          }}
                        >
                          <Box sx={{ pr: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {getModelIcon(model)}
                          </Box>
                          {`${model.charAt(0).toUpperCase() + model.slice(1)} Data`}
                        </MUIButton>
                      </Box>
                    ))}
                     <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <MUIButton
                        variant="contained"
                        sx={{
                          height: '30px',
                          width: '250px',
                        }}
                        onClick={() => {
                          //
                        }}
                      >
                        <Box sx={{ pr: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <CustomerIcon />
                        </Box>
                        Customer Data
                      </MUIButton>
                    </Box>
                    <Box>
                      <MUIButton
                        variant="contained"
                        sx={{
                          height: '30px',
                          width: '150px',
                          backgroundColor: '#fff',
                          color: '#1D252D',
                          ':hover': {
                            backgroundColor: '#DAEFFF',
                          },
                        }}
                        onClick={() => {
                          // props.history.push('/markets');
                          // show vehicle data
                        }}
                      >
                        <Box sx={{ pr: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <VehicleIcon />
                        </Box>
                        Vehicle Data
                      </MUIButton>
                    </Box>
                   </Stack> */}
                </Box>
                <Box sx={{
              pt: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}>
                  <CustomerIcon size={24} sx={{
                pr: '10px'
              }} />
                  <Typography>{`Customer Data`}</Typography>
                </Box>

                <Stack id="stack-table-preview-fields">
                  <Box id="box-scrollable-table-fields" sx={{
                overflow: 'auto',
                height: '43vh',
                p: 0,
                border: '1px solid #E2E2E2',
                borderRadius: '14px'
              }}>
                    {!isLoading ?
                // TODO: this table should only render the preview to the specific model now. uploadedFile.maps has the model for each mapped field
                <Table variant="outlined" sx={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  borderSpacing: 0,
                  '& th': {
                    backgroundColor: '#F9F9F9',
                    border: '1px solid #E2E2E2',
                    padding: '8px',
                    textAlign: 'left',
                    borderRight: 'none',
                    borderLeft: 'none'
                  },
                  '& td': {
                    border: '1px solid #E2E2E2',
                    padding: '8px',
                    textAlign: 'left',
                    borderRight: 'none',
                    borderLeft: 'none'
                  }
                }}>
                        <TableHead>
                          <TableRow>
                            {/* using tempRecords for simplicity  */}
                            {tempRecords.map((record, index) => <TableCell key={index} sx={{
                        height: '60px',
                        p: '12px 16px',
                        gap: '10px'
                      }}>
                                <Typography noWrap>{record.source_key.charAt(0).toUpperCase() + record.source_key.slice(1)}</Typography>
                              </TableCell>)}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* row data from preview_data should populate here */}
                          {}
                        </TableBody>
                      </Table> : <>
                        {isViewingSummary && isLoading && <Box sx={{
                    height: 'auto'
                  }}>
                            <Stack direction="row" spacing={2}>
                              <CircularProgress sx={{
                        color: '#FFB82B'
                      }} size={'20px'} />
                              <Typography sx={{
                        opacity: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                                Generating Preview...
                              </Typography>
                            </Stack>
                          </Box>}
                      </>}
                  </Box>
                </Stack>
              </Box> : null}
          </>

          {/* <div style={{ position: 'absolute', top: paperHeight >= 950 ? '920px' : '80vh', left: '0', right: '0' }}> */}
          <div style={{
          position: 'static',
          marginTop: '-25px',
          marginLeft: '-20px',
          marginRight: '-20px'
        }}>
            <Divider id="bottom-wizard-divider" />
          </div>
          <Stack direction="row" sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pt: '20px',
          pl: '26px',
          position: 'absolute',
          width: '97%',
          bottom: '20px',
          left: '0',
          right: '0'
        }}>
            <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            minWidth: '126px'
          }}>
              {wizardStepCount > 0 && <MUIButton variant="contained" sx={{
              width: '126px',
              height: '32px',
              backgroundColor: '#F3F3F4',
              '&:hover': {
                color: '#fff'
              }
            }} onMouseEnter={() => {
              setHoverIconColorButton1('#fff');
            }} onMouseLeave={() => {
              setHoverIconColorButton1('#3898D9');
            }} onClick={() => {
              setLoseStepDataWarning(true);
            }}>
                  <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transform: 'scaleX(-1)'
              }}>
                    <ArrowRightAltIcon fill={hoverIconColorButton1} />
                  </Box>

                  <Typography sx={{
                p: '8px',
                color: hoverIconColorButton1
              }}>Previous</Typography>
                </MUIButton>}
            </Box>
            <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '-30px'
          }}></Box>
            <Box sx={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'flex-end'
          }}>
              <MUIButton variant="contained" sx={{
              width: wizardStepCount === 4 ? '114px' : '100px',
              height: '32px'
            }} disabled={
            // TODO: disable if they didnt select a config, its missing from these checks
            isLoading || uploadedFile?.source === 'Other' && uploadedFile.other.trim() === ''} onClick={() => {
              wizardStepHandler(wizardStepCount + 1);
            }}>
                {isLoading ? <>
                    <Typography sx={{
                  p: '8px',
                  color: '#fff'
                }}>Next</Typography>
                    <CircularProgress sx={{
                  color: '#fff'
                }} size={'20px'} />
                  </> : <>
                    <Typography sx={{
                  p: '8px',
                  color: '#fff'
                }}>{wizardStepCount === 4 ? 'Import' : 'Next'}</Typography>
                    <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                      {wizardStepCount === 4 ? <span style={{
                    fontSize: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                          <ImportIcon fill={'#fff'} />
                        </span> : <ArrowRightAltIcon fill={'#fff'} />}
                    </Box>
                  </>}
              </MUIButton>
            </Box>
          </Stack>
        </Paper>
      </div>

      <Dialog open={confirmUpload} onClose={() => {
      setConfirmUpload(false);
    }} PaperProps={{
      sx: {
        borderRadius: '28px',
        height: '407px',
        width: '560px',
        overflow: 'hidden'
      }
    }}>
        <Box sx={{
        p: '20px'
      }}>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}>
            <Typography sx={{
            py: '16px',
            fontSize: '24px',
            pl: '10px'
          }}>Confirm Import</Typography>
          </Box>
          <DialogContent sx={{
          p: '10px',
          overflow: 'visible'
        }}>
            <Box>
              <Typography>
                {uploadedFile && uploadedFile.total_records === 0 ? 'There are no records detected in this CSV file. Please check the file and import again.' : `Please confirm you want to load these ${uploadedFile?.total_records} records into ${asCompany.name}.`}
              </Typography>
            </Box>
            <Divider id="top-divider-confirm" sx={{
            mt: '20px',
            mb: '20px',
            ml: '-30px',
            mr: '-30px'
          }} />
            <Stack direction="row" sx={{
            pt: '16px'
          }}>
              <Box sx={{
              pr: '10px'
            }}>
                <Checkbox sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'justify',
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }} checked={agree1} onChange={event => {
                setAgree1(event.target.checked);
              }}></Checkbox>
              </Box>
              <Box>
                <Typography>
                  I understand that importing new records may impact my monthly pricing for Cinch. (Please see your contract or contact{' '}
                  <a href="mailto:support@cinch.io">support@cinch.io</a> for more information)
                </Typography>
              </Box>
            </Stack>

            <Stack direction="row" sx={{
            pt: '16px'
          }}>
              <Box sx={{
              pr: '10px'
            }}>
                <Checkbox sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'justify',
                p: 0,
                margin: 0,
                color: '#1D252D',
                '&.Mui-checked': {
                  color: '#53A6D6'
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 22
                }
              }} checked={agree2} onChange={event => {
                setAgree2(event.target.checked);
              }}></Checkbox>
              </Box>
              <Box>
                <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                  I certify that I have permission to contact or market to these customer records
                </Typography>
              </Box>
            </Stack>
          </DialogContent>
          <Divider id="bottom-divider-confirm" sx={{
          mt: '20px',
          mb: '20px',
          ml: '-20px',
          mr: '-20px'
        }} />

          <DialogActions sx={{
          pr: '10px',
          pt: '40px'
        }}>
            <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          }}>
              <MUIButton variant="contained" sx={{
              width: '94px',
              height: '32px',
              backgroundColor: '#F3F3F4',
              '&:hover': {
                color: '#fff'
              }
            }} onMouseEnter={() => {
              setHoverIconColorButton2('#fff');
            }} onMouseLeave={() => {
              setHoverIconColorButton2('#3898D9');
            }} onClick={() => {
              setConfirmUpload(false);
            }}>
                <Typography sx={{
                p: '8px',
                color: hoverIconColorButton2
              }}>Cancel</Typography>
              </MUIButton>
            </Box>
            <MUIButton variant="contained" sx={{
            width: '100px',
            height: '32px'
          }} disabled={!(agree1 && agree2 && uploadedFile.total_records > 0)} onClick={() => {
            if (agree1 && agree2) {
              actuallyUpload();
              setConfirmUpload(false);
            }
          }}>
              Import
            </MUIButton>
          </DialogActions>
        </Box>
      </Dialog>

      <Modal open={!!uniqueIdModal} onClose={() => setUniqueIdModal(true)} allowBackdropClick title="Unique ID Information">
        <div className="modal__body">
          <p>
            Check this box if your data set contains a unique identifier (meaning that this identifier or value should only exists once in
            the data). When you check this box next to the appropriate unique ID, Cinch will check if value already exists in your database,
            and if it does, it will update the existing record instead of creating a new record.
          </p>
          <p>
            For example, if you selected “Email” as your unique ID, Cinch will check if the email addresses in the CSV you’re loading have
            been loaded before, and if they have, it will just update the existing records with a matching email address”
          </p>
        </div>
        <div className="modal__actions">
          <Button actionType="primary" onClick={() => setUniqueIdModal(false)}>
            Okay
          </Button>
        </div>
      </Modal>

      <Dialog open={loseStepDataWarning} onClose={() => setLoseStepDataWarning(false)} PaperProps={{
      sx: {
        borderRadius: '28px',
        height: '245px',
        width: '312px',
        overflow: 'hidden'
      }
    }}>
        <Box>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pt: '24px'
        }}>
            <WarningRedIcon />
          </Box>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
            <Typography sx={{
            pt: '16px',
            fontSize: '24px'
          }}>Progress Warning</Typography>
          </Box>
          <DialogContent>
            <Box>
              <Typography sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'justify'
            }}>
                Are you sure you want to go back? You will lose all the data you have entered in this step.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{
          pr: '20px',
          pt: '25px'
        }}>
            <MUIButton variant="text" sx={{
            textTransform: 'none',
            borderRadius: '20px'
          }} onClick={() => setLoseStepDataWarning(false)}>
              Cancel
            </MUIButton>
            <MUIButton variant="text" sx={{
            textTransform: 'none',
            borderRadius: '20px'
          }} onClick={() => {
            wizardStepHandler(wizardStepCount - 1);
            setLoseStepDataWarning(false);
          }}>
              Confirm
            </MUIButton>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog open={resetWizardWarning} onClose={() => setResetWizardWarning(false)} PaperProps={{
      sx: {
        borderRadius: '28px',
        height: '245px',
        width: '312px',
        overflow: 'hidden'
      }
    }}>
        <Box>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pt: '24px'
        }}>
            <WarningRedIcon />
          </Box>
          <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
            <Typography sx={{
            pt: '16px',
            fontSize: '24px'
          }}>Replace import file</Typography>
          </Box>
          <DialogContent>
            <Box>
              <Typography sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'justify'
            }}>
                Doing this will remove all import configurations, and you will need to restart the import process from the beginning of the
                wizard.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{
          pr: '20px'
        }}>
            <MUIButton variant="text" sx={{
            textTransform: 'none',
            borderRadius: '20px'
          }} onClick={() => setResetWizardWarning(false)}>
              Cancel
            </MUIButton>
            <MUIButton variant="text" sx={{
            textTransform: 'none',
            borderRadius: '20px'
          }} onClick={() => {
            setResetWizardWarning(false);
            setWizardStepCount(0);
            setIsUploadingFile(true);
            setIsSelectingOptions(false);
            setIsSelectingFields(false);
            setIsSelectingUniqueID(false);
            setIsViewingSummary(false);
          }}>
              Confirm
            </MUIButton>
          </DialogActions>
        </Box>
      </Dialog>
    </Scoped>;
};